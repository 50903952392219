import imgGoodEnergy from './assets/images/good_energy.jpg';
import imgItStarts from './assets/images/it_starts_with_us.jpg';
import imgTellMe from './assets/images/tell_me_everything.jpg';
const books = [
  {
    title:
      'Good Energy: The Surprising Connection Between Metabolism and Limitless Health',
    author: 'Casey Means MD with Calley Means',
    img: imgGoodEnergy,
    id: 1,
  },
  {
    title: 'It Starts with Us: A Novel (It Ends with Us)',
    author: 'Colleen Hoover',
    img: imgItStarts,
    id: 2,
  },
  {
    title: 'Tell Me Everything',
    author: 'Elizabeth Strout',
    img: imgTellMe,
    id: 3,
  },
];

export default books;
