import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import books from './books.js';
import Book from './Book.js';

const BookList = () => {
  return (
    <section className='booklist'>
      <h1 className='title'>Amazon Best Sellers</h1>
      {books.map((book, index) => {
        return <Book {...book} key={book.id} index={index} />;
      })}
    </section>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BookList />);
